<template>
  <div class="awards">
    <div class="header">
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../../static/images/home-b-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;object-fit:cover;vertical-align: super;margin-left: 0.1rem;" src="../../static/images/home-b-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../../static/images/home-s-b.png" alt="">
        </div>
      </div>
      <div class="header-text">ABOUT US</div>
    </div>
    <div class="content">
      <div class="solution-title">
        <div class="title-text">荣 誉 奖 项</div>
        <div class="title-text-e">SourceHanSansCN-Bold</div>
      </div>
      <div style="width:9.19rem;height:4.27rem;margin:auto;margin-top:1.43rem;">
        <img style="width:100%;height:100%;object-fit:cover;" src="../../static/images/jb.png" alt="">
      </div>
      <div style="width:8.27rem;height:2.63rem;margin:auto;">
        <img style="width:100%;height:100%;object-fit:cover;" src="../../static/images/zs.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Awards',
    methods: {
      jumpToHome() {
        this.$router.push('/')
      },
      jumpToHome1() {
        this.$router.push('/home2')
      },
    }
  }
</script>

<style lang="scss" scoped>
.header{
  position: relative;
  padding: 0.26rem 0.4rem 0 0.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 6.05rem;
  background: url('../../static/images/synopsis-bj.png');
  background-size: 100% 100%;
}
.header-img{
  display: flex;
  justify-content: space-between;
}
.header-text{
  width: 4.6rem;
  position: absolute;
  margin-left: -1.6rem;
  top: 52%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
}
.content{
  margin-top: 1.25rem;
}
.solution-title {
    margin: auto;
    .title-text {
      font-size: 0.56rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #27201b;
      text-align: center;
    }
    .title-text-e {
      margin-top: -0.45rem;
      text-align: center;
      font-size: 0.56rem;
      font-family: DINCond-Bold;
      font-weight: 600;
      color: #27201b;
      opacity: 0.1;
      letter-spacing:3px;
    }
  }
</style>